<template>
  <div class="base-content">
    <van-config-provider :theme-vars="themeVars"
                         class="pss-body"
                         style="flex:1;height:1%">
      <van-nav-bar :title="navBarTitle"
                   v-if="navBar.show"
                   :left-arrow="navBar.leftArrow"
                   @click-left="onNavBarClickLeft"
                   :style="navBar.style"
                   class="pss-navbar" />
      <div class="pss-content">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component"
                       v-if="$route.meta.keepAlive"
                       :key="$route.path + userId" />
          </keep-alive>
          <component :is="Component"
                     v-if="!$route.meta.keepAlive" />
          <!-- 进度条 -->
          <div class="pss-map-loading"  v-if="mapLoadShow && mapLoadingNum < 100">
            <img src="/img/startUp.png"
                alt=""
                class="loading-bg">
            <div class="loading-content">
              <van-loading size="16px">加载中...</van-loading>
              <van-progress :percentage="mapLoadingNum"
                            pivot-color="rgba(0, 130, 255, 1)"
                            color="linear-gradient(to right, rgba(0, 130, 255, .5), rgba(0, 130, 255, 1))" />
            </div>
          </div>
        </router-view>
      </div>
    </van-config-provider>
    <van-tabbar route
                v-if="tabbarShow && !mapLoadShow"
                class="pss-tabbar">
      <van-tabbar-item replace
                       to="/home">
        <div class="pss-icon i1"></div>
        <div class="pss-icon-active i1"></div>
        <span class="tabbar-span">首页</span>
      </van-tabbar-item>
      <van-tabbar-item replace
                       to="/mine">
        <div class="pss-icon i3"></div>
        <div class="pss-icon-active i3"></div>
        <span class="tabbar-span">我的</span>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>

import {
  computed,
} from 'vue'
import { NavBar, Tabbar, TabbarItem,Loading,Progress,Image as VanImage } from 'vant';
import { useStore } from '@/store';
import { useRoute,useRouter } from 'vue-router'

import { ACCESS_TOKEN_KEY, COMMON_DES_KEY } from "./common/keys";
import { CommonActionTypes } from '@/store/modules/base/modules/common/action-types'
import { UserActionTypes } from '@/store/modules/base/modules/user/action-types'
import CryptoJS from "./assets/js/crypto-js.min";
import Tools from "./assets/js/tools";
import cache from 'common/cache'
window.CryptoJS = CryptoJS;

export default {
  components: {
    [NavBar.name]: NavBar,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Loading.name]: Loading,
    [VanImage.name]: VanImage,
    [Progress.name]: Progress,
  },
  setup () {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const accessToken = Tools.getLocal(COMMON_DES_KEY, ACCESS_TOKEN_KEY);
    if (accessToken) {
      store.dispatch(`base/user/${UserActionTypes.SET_TOKEN}`, accessToken);
    }

    const themeVars = {
      navBarHeight: '56px',
      navBarArrowSize: '25px'
    };

    const tabbarShow = computed(() => {
      return store.state.base.common.tabbarShow
    })

    const navBar = computed(() => {
      return store.state.base.common.navBar
    })

    const navBarTitle = computed(() => {
      return store.state.base.common.navBar.title || store.state.base.user.project?.name || '普仕昇3D车位选购'
    })

    const mapLoadShow = computed(() => {
      return store.state.base.common.mapLoadShow
    })

    const mapLoadingNum = computed(() => {
      return store.state.base.common.mapLoadingNum
    })

    const userId = computed(() => {
      return store.state.base.user?.info?.id || -1
    })

    const onNavBarClickLeft = () => {
      if (store.state.base.common.navBar.leftArrowFn === undefined || store.state.base.common.navBar.leftArrowFn === null) {
        let step = -1;
        if(route.query.backStep !== null && route.query.backStep !== undefined) {
          step = -Number(route.query.backStep);
        }
        let href1 = location.href;
        history.go(step);
        setTimeout(()=>{ 
          if(location.href === href1) {
            router.push("/home");
          }
        },200)
      } else {
        store.state.base.common.navBar.leftArrowFn();
      }
    }

    router.beforeEach((to) => {
      if(to.name === 'psp') {
        store.dispatch(`base/common/${CommonActionTypes.SET_MAP_LOADING_SHOW}`,true)
      } else {
        store.dispatch(`base/common/${CommonActionTypes.SET_MAP_LOADING_SHOW}`,false)
      }
    })
    cache.start();

    return {
      themeVars,
      tabbarShow,
      navBar,
      onNavBarClickLeft,
      navBarTitle,
      mapLoadShow,
      mapLoadingNum,
      userId
    };
  },
};
</script>

<style lang="less">
.pss-map-loading {
  width   : 100%;
  height  : 100%;
  position: fixed;
  top     : 0;
  left    : 0;
  z-index : 10000;

  background-color:#141b29;

  .loading-bg {
    width   : 100%;
    height  : 100%;
    position: absolute;
    top     : 0;
    left    : 0;
    z-index : 1;
  }

  .loading-content {
    position       : absolute;
    left           : 16px;
    right          : 16px;
    bottom         : calc(16px + constant(safe-area-inset-bottom));
    bottom         : calc(16px + env(safe-area-inset-bottom));
    background     : rgba(10, 15, 20, .6);
    backdrop-filter: blur(4px);
    border-radius  : 8px;
    padding        : 16px;
    z-index        : 10;

    .van-loading {
      margin-bottom: 16px;

      .van-loading__text {
        color: rgba(255, 255, 255, .8)
      }
    }

    .van-progress {
      margin: 8px 0;
    }
  }
}
</style>

<style>
@import "assets/css/base.css";
</style>
